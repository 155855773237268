import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <ol>
      <li>
        <a href='/1 - Transformed Allegiance - Living for Christ our King and His Kingdom.docx'>
          Transformed Allegiance - Living for Christ our King and His Kingdom
        </a>
      </li>
      <li>
        <a href='/2 - Transformed Mind - Seeking to Obtain a Transformed Mind.docx'>
          Transformed Mind - Seeking to Obtain a Transformed Mind
        </a>
      </li>
      <li>
        <a href='/3 - Transformed Heart - Seeking to Obtain a Transformed Character.docx'>
          Transformed Heart - Seeking to Obtain a Transformed Character
        </a>
      </li>
      <li>
        <a href='/4 - Transformed Habits - Becoming a person a devotion and discipline.docx'>
          Transformed Habits - Becoming a person a devotion and discipline
        </a>
      </li>
      <li>
        <a href='/5 - Transformed Relationships - Seeking to Live in Relationships Transformed by Christ.docx'>
          Transformed Relationships - Seeking to Live in Relationships Transformed by Christ
        </a>
      </li>
      <li>
        <a href='/6 - Transformed Service - Practices the Life of a Servant Like Jesus.docx'>
          Transformed Service - Practices the Life of a Servant Like Jesus
        </a>
      </li>
      <li>
        <a href='/7 - Transformed Influence - Becoming a Person of Multiplying Influence.docx'>
          Transformed Influence - Becoming a Person of Multiplying Influence
        </a>
      </li>
    </ol>
  </Layout>
)

export default IndexPage
